html,
body {
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: "Nunito";
  src: url("/src/assets/font/Nunito-Regular.ttf");
}
@font-face {
  font-family: "Inter";
  src: url("/src/assets/font/inter.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("/src/assets/font/Poppins-Regular.ttf");
}
